<template>
    <input
        type="text"
        placeholder="0.00%"
        maxlength="5"
        :value="modelValue"
        @keydown="validateKeydown"
        @blur="formatInput"
        @focus="removeMask"
    />
</template>

<script>
export default ({
    name: 'PercentageInput',
    props: {
        "modelValue": String    
    }, 
    data () {
        return {
            hasDecimal: false,
            formatted: "",
            raw: ""
        }
    },
    computed: {
        cmdKey: {
            get () { 
                if (this.getBrowser() == "firefox") {
                    return 224
                }
                return 91
            }
        }
    },
    methods: {
        getBrowser() {
            let agent = navigator.userAgent
            if (agent.match("/chrome|chromium|crios/i")) return "chrome"
            if (agent.match("/firefox|fxios/i")) return "firefox"
            if (agent.match("/safari/i")) return "safari"
            if (agent.match("/edg/i")) return "edge"
        },
        validateKeydown(e) {
            var key = e.key // event.key press
            // if non-number prevent typing
            this.hasDecimal = e.target.value.includes('.')
            if (!(key == 'Backspace' || key == 'ArrowLeft' || key == 'ArrowRight' || key == 'Tab' || key == 'Delete' || e.keycode == this.cmdKey || e.ctrlKey)) {
                if (key.match(`[^0-9cv.]+`)) {
                    e.preventDefault()
                } else if (key.match(`[.]+`) && this.hasDecimal) {
                    e.preventDefault()
                }
            } 
        },
        formatInput(e) {
            // format number
            this.formatted = this.wearMask(e.target.value)
            this.$emit('update:modelValue', this.formatted)
        },
        wearMask(str) {
            if (str === null || str.length == 0) {
                return 
            }
            str = String(+str)
            if (str == "") return 
            str = this.decimalize(str)
            
            let formatted = `${parseFloat(str)}%`
            return formatted
        },
        removeMask(e) {
            this.raw = e.target.value.replace("%", "")
            this.$emit('update:modelValue', this.raw)
        },
        decimalize(str) {
            if (str.includes('.')) {
                let ind = str.indexOf('.')
                if (ind == 0) {
                    str = `0${str}`
                    ind += 1
                }
                let deci = str.substring(ind+1, str.length)
                str = `${str.substring(0,ind)}.${deci}`
                this.hasDecimal = true
            } else {
                this.hasDecimal = false
            }
            return str
        }
    }
})
</script>